import { createWebHistory, createRouter } from "vue-router";
const routes =  [
  {
    path: "/evaluation",
    alias: "/evaluation",
    name: "evaluation",
    component: () => import("./components/EvaluationTest")
  },
  {
    path: "/evaluation_result",
    alias: "/evaluation_result",
    name: "evaluation_result",
    component: () => import("./components/EvaluationTestResult")
  },
  {
    path: "/preferences",
    alias: "/preferences",
    name: "preferences",
    component: () => import("./components/Preference")
  },
  {
    path: "/tea",
    alias: "/tea",
    name: "tea",
    component: () => import("./components/Tea")
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;