import { createApp } from 'vue'
import { setupI18n } from './i18.js'
import App from './App.vue'
import router from './router'
import './assets/styles/styles.css'
import './assets/styles/bootstrap.css'
import './assets/styles/main.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

const i18n = setupI18n()

createApp(App).use(i18n).use(router).use(VueAxios, axios).mount('#app')
