<template>
    <div id="app">

        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <img class="navbar-brand-logo" src='@/assets/img/aiTeaTechLogo.jpeg'/>
                <button style="background: none; border: none;" v-on:click="changeLocale(locale)" v-for="locale in SUPPORTED_LOCALES" v-bind:key="locale">
                    <img :src="require('@/assets/img/flag/' + locale + '.png')">
                </button>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    {{ $t("MENU") }}
                    <i class="fas fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ml-auto">
                        <li class="nav-item">
                            <router-link to="/evaluation">
                                <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit">{{ $t('body_checks_page') }}</button>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit">{{ $t('LOGIN') }}</button>
                        </li>
                        <li class="nav-item">
                            <button id="sendMessageButton" class="btn btn-primary btn-xl text-uppercase" type="submit">{{ $t('REGISTER') }}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Header -->
        <header class="masthead">
            <div class="container">
                <div class="intro-text">
                </div>
            </div>
        </header>

        <div class="container mt-3">
            <router-view />
        </div>

        <!-- Footer -->
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <span class="copyright">Copyright &copy; aiTeaTech 2022</span>
                    </div>
                    <div class="col-md-4">
                        <ul class="list-inline social-buttons">
                            <li class="list-inline-item">
                                <a href="#">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <ul class="list-inline quicklinks">
                            <li class="list-inline-item">
                                <a href="#">Privacy Policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

import { SUPPORTED_LOCALES } from '@/i18.js'

export default {
    name: 'app',
    components: {
    },
    data() {
        return {
            model: null,
            SUPPORTED_LOCALES: SUPPORTED_LOCALES
        }
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
            localStorage.locale = locale;
        }
    }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

body {
    background: black;
}
</style>
